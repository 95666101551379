import React, { useState } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useFetchDeliveryDetailsQuery } from "../../../store";
import { Button } from "@material-tailwind/react";
import { FaFileExcel } from "react-icons/fa";

const DelSummaryExcel = ({ getDelId }) => {
  const { data, isError, isLoading, isSuccess } =
    useFetchDeliveryDetailsQuery(getDelId);
  const [isExcelLoading, setIsExcelLoading] = useState(false);

  const generateExcel = async () => {
    setIsExcelLoading(true);
    if (isSuccess && data) {
      const result = data.data;
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("Delivery Summary");
      await sheet.protect("BM2025", {
        selectLockedCells: true,
        selectUnlockedCells: true,
        formatCells: false,
        formatColumns: false,
        formatRows: false,
        insertColumns: false,
        insertRows: false,
        deleteColumns: false,
        deleteRows: false,
      });
      // Add title
      sheet.mergeCells("A2:D2");
      sheet.getCell("A2").value = "PREMIUM BURGER & FOOD PRODUCT INC.";
      sheet.getCell("A2").font = {
        bold: true,
        size: 16,
        color: { argb: "FF008000" },
      };
      sheet.getCell("A2").alignment = { horizontal: "center" };

      sheet.mergeCells("A3:D3");
      sheet.getCell("A3").value =
        "1185 TOPAZ ST. RAMAR VILLAGE SAN AGUSTIN CITY OF SAN FERNANDO PAMPANGA";
      sheet.getCell("A3").alignment = { horizontal: "center" };

      sheet.mergeCells("A4:D4");
      sheet.getCell("A4").value = "DELIVERY SUMMARY RECEIPT";
      sheet.getCell("A4").font = { bold: true };
      sheet.getCell("A4").alignment = { horizontal: "center" };

      // Add delivery information
      const deliveryDate = new Date(
        result.delivery.delivery_date
      ).toLocaleString("en-US", { timeZone: "Asia/Tokyo" });
      sheet.addRow([]);
      sheet.addRow([
        "Delivery Date:",
        deliveryDate,
        "Cage Number:",
        result.delivery.cage_number,
      ]);
      sheet.addRow([
        "Delivery Number:",
        result.delivery.delivery_number,
        "Route:",
        result.delivery.route.name,
      ]);
      sheet.addRow([
        "Transfer Number:",
        result.delivery.transfer.transfer_number,
      ]);
      sheet.addRow([]);

      // Add table headers
      sheet.addRow(["Code", "Name", "Total Issued Qty"]).font = {
        bold: true,
      };
      sheet.columns = [
        { header: "Code", key: "code", width: 15 },
        { header: "Name", key: "name", width: 30 },
        { header: "Total Issued Qty", key: "quantity", width: 20 },
        // { header: "Remarks", key: "remarks", width: 20 },
      ];

      let totalOrderedQty = 0; // Initialize total ordered quantity

      // Add delivery items
      result.delivery_items
        .filter((item) => item.ordered_qty !== 0)
        .forEach((item) => {
          totalOrderedQty += item.ordered_qty; // Sum up ordered_qty

          sheet.addRow([
            item.product__product_code,
            item.product__nickname,
            `${item.ordered_qty} ${item.product__unit_measurement}`,
            "",
          ]);
        });

      // Add total packs row
      sheet.addRow([]); // Empty row for spacing
      const totalRow = sheet.addRow(["", "Total Packs:", totalOrderedQty, ""]);
      totalRow.font = { bold: true }; // Make it bold
      sheet.mergeCells("A1:D1");
      sheet.getCell("A1").value = "";
      // Save the Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        `BM_${result.delivery.delivery_number}_Cage_Summary.xlsx`
      );
      setIsExcelLoading(false);
    }
  };

  return (
    <div>
      <Button
        className="bg-green-700 mt-1 py-3 px-3 flex items-center space-x-2"
        onClick={generateExcel}
        disabled={isLoading || isError || isExcelLoading}
      >
        {isLoading || isError || isExcelLoading ? (
          "Loading..."
        ) : (
          <>
            <FaFileExcel className="mr-2" />
          </>
        )}
      </Button>
    </div>
  );
};

export default DelSummaryExcel;
