import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");

const productApi = createApi({
  reducerPath: "production",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  endpoints(builder) {
    return {
      fetchProductBakeryList: builder.query({
        query: ({ page, page_size = 10, search_str }) => {
          return {
            url: `/api/production/product_bakery_pagelist/?page=${page}&page_size=${page_size}&search_str=${search_str}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchProductPattyList: builder.query({
        query: ({ page, page_size = 10, search_str }) => {
          return {
            url: `/api/production/product_patty_pagelist/?page=${page}&page_size=${page_size}&search_str=${search_str}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchProductSandwichLineList: builder.query({
        query: ({ page, page_size = 10, search_str }) => {
          return {
            url: `/api/production/product_sandwichline_pagelist/?page=${page}&page_size=${page_size}&search_str=${search_str}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchProductKetsupList: builder.query({
        query: ({ page, page_size = 10, search_str }) => {
          return {
            url: `/api/production/product_ketsup_pagelist/?page=${page}&page_size=${page_size}&search_str=${search_str}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchProductCakePastriesList: builder.query({
        query: ({ page, page_size = 10, search_str }) => {
          return {
            url: `/api/production/product_cakepastries_pagelist/?page=${page}&page_size=${page_size}&search_str=${search_str}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchProductBreadCrumbsBunsList: builder.query({
        query: ({ page, page_size = 10, search_str }) => {
          return {
            url: `/api/production/prod_breadcrumbs_bun_pagelist/?page=${page}&page_size=${page_size}&search_str=${search_str}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchProductBreadCrumbsList: builder.query({
        query: ({ page, page_size = 10, search_str }) => {
          return {
            url: `/api/production/product_breadcrumbs_pagelist/?page=${page}&page_size=${page_size}&search_str=${search_str}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      addProductionSchedule: builder.mutation({
        query: (formData) => {
          return {
            url: `/api/production/add_production/`,
            headers: {
              Authorization: "token " + token,
              "Content-Type": "application/json",
              Accept: "*/*",
            },
            method: "POST",
            body: formData,
          };
        },
      }),
      fetchSchedule: builder.query({
        query: ({ page, page_size, status, job_position }) => {
          return {
            url: `/api/production/get_production_list/?page=${page}&page_size=${page_size}&status=${status}&job_position=${job_position}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchTransferProduct: builder.query({
        query: ({ page, page_size, user_id }) => {
          return {
            url: `/api/production/get_transfer_production_list/?page=${page}&page_size=${page_size}&user_id=${user_id}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchScheduleProduct: builder.query({
        query: (id) => {
          return {
            url: `/api/production/${id}/detailed_schedule_product/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchProductLineCompleted: builder.query({
        query: (id) => {
          return {
            url: `/api/production/${id}/detailed_prod_completed/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchProdItemLineCompleted: builder.query({
        query: (id) => {
          return {
            url: `/api/production/${id}/detailed_prod_item_completed/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDetailTransferProduct: builder.query({
        query: (id) => {
          return {
            url: `/api/production/${id}/detailed_transfer_production/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchMixesList: builder.query({
        query: ({ page, page_size = 10, search_str, mixes }) => {
          return {
            url: `/api/production/premixes/?page=${page}&page_size=${page_size}&search_str=${search_str}&mixes=${mixes}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      transferRawMats: builder.mutation({
        query: (form) => {
          return {
            url: `/api/production/transfer_products/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      newTransferRawMats: builder.mutation({
        query: (form) => {
          return {
            url: `/api/production/new_transfer_products/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      receivedProducts: builder.mutation({
        query: (form) => {
          return {
            url: `/api/production/received_products/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      transferOutOfStock: builder.mutation({
        query: (request) => {
          return {
            url: `/api/production/transfer_prod_out_of_stock/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: request,
          };
        },
      }),
      countRecipeOfProduct: builder.mutation({
        query: (request) => {
          return {
            url: `/api/production/count_recipe_of_product/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: request,
          };
        },
      }),
      saveSchedProduct: builder.mutation({
        query: (request) => {
          return {
            url: `/api/production/save_sched_product/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: request,
          };
        },
      }),
      saveSchedItem: builder.mutation({
        query: (request) => {
          return {
            url: `/api/production/save_sched_item/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: request,
          };
        },
      }),
      saveSchedInput: builder.mutation({
        query: (request) => {
          return {
            url: `/api/production/save_sched_input/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: request,
          };
        },
      }),
      saveSchedSignature: builder.mutation({
        query: (request) => {
          return {
            url: `/api/production/save_sched_signature/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: request,
          };
        },
      }),
      saveSchedProductDetail: builder.mutation({
        query: (request) => {
          return {
            url: `/api/production/save_sched_product_detail/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: request,
          };
        },
      }),
      saveSchedItemDetail: builder.mutation({
        query: (request) => {
          return {
            url: `/api/production/save_sched_item_detail/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: request,
          };
        },
      }),
      premixesRecipeComputation: builder.query({
        query: ({ id, batch }) => {
          return {
            url: `/api/production/${id}/pre_rep_com/?batch=${batch}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      saveTransItemPlasInput: builder.mutation({
        query: (request) => {
          return {
            url: `/api/production/save_trans_item_plas_input/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: request,
          };
        },
      }),
      updateClientItem: builder.mutation({
        query: (request) => {
          return {
            url: `/api/production/client_item_update/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: request,
          };
        },
      }),
    };
  },
});
export const {
  useFetchProductBakeryListQuery,
  useFetchProductPattyListQuery,
  useFetchProductSandwichLineListQuery,
  useFetchProductKetsupListQuery,
  useFetchProductCakePastriesListQuery,
  useFetchProductBreadCrumbsBunsListQuery,
  useFetchProductBreadCrumbsListQuery,
  useAddProductionScheduleMutation,
  useFetchScheduleQuery,
  useFetchTransferProductQuery,
  useFetchDetailTransferProductQuery,
  useFetchMixesListQuery,
  useFetchScheduleProductQuery,
  useTransferRawMatsMutation,
  useTransferOutOfStockMutation,
  useCountRecipeOfProductMutation,
  useSaveSchedProductMutation,
  useSaveSchedInputMutation,
  useSaveSchedSignatureMutation,
  useSaveSchedProductDetailMutation,
  useNewTransferRawMatsMutation,
  useFetchProductLineCompletedQuery,
  useFetchProdItemLineCompletedQuery,
  useSaveSchedItemMutation,
  useSaveSchedItemDetailMutation,
  usePremixesRecipeComputationQuery,
  useReceivedProductsMutation,
  useSaveTransItemPlasInputMutation,
  useUpdateClientItemMutation,
} = productApi;
export { productApi };
