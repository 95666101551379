import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@material-tailwind/react";
import { useFetchDeliveryApprovalQuery } from "../../store/apis/ApprovalApi";
import { TbTransferIn } from "react-icons/tb";
import HashLoader from "react-spinners/HashLoader";
import PageTitle from "../page_components/PageTitle";
import ApprovalTabComponents from "../page_components/ApprovalTabComponents";
import { CompleteTransferDeliveryTableView } from "../page_components/Complete_Table_TransferDelivery_View";
import { CompleteTransferRequestTableView } from "../page_components/Complete_Table_TransferRequest";
import { CompleteTransferWithdrawalTableView } from "../page_components/Complete_Table_TransferWithdrawal";
import { CompleteTransferRTSTableView } from "../page_components/Complete_Table_TransferRTS";
import TransferProduction from "../page_components/TransferProduction";
import TransferGoodReject from "../page_components/TransferGoodReject";

let fetchDelResult = [];

const TABLE_HEAD_DEL = [
  "Transfer #",
  "Deliver #",
  "Department",
  "Requested by",
  "Cage #",
  "Date",
  "View",
  "Status",
];

const TABLE_HEAD_WITH = [
  "Transfer #",
  "Outlet",
  "Department",
  "Driver/Collector",
  "Date",
  "View",
  "Status",
];

const TABLE_HEAD_REQ = [
  "Transfer #",
  "Department",
  "Requested by",
  "Date",
  "View",
  "Status",
];

const TABLE_HEAD_RTS = [
  "Transfer #",
  "Vendor",
  "Department",
  "Requested by",
  "Date",
  "View",
  "Status",
];

function Approval() {
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  useEffect(() => {
    if (
      account_detailed.job_position == 1 ||
      account_detailed.job_position == 5 ||
      account_detailed.job_position == 6 ||
      account_detailed.job_position == 7 ||
      account_detailed.job_position == 8 ||
      account_detailed.user_type == 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position === 14
    ) {
      // pass
    } else {
      return;
    }
  }, [token]);

  const [delCurrentPage, setdelCurrentPage] = useState(1);
  const [delPageSize, setdelPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState("asc");

  const {
    data: delData,
    error: delError,
    isLoading: delLoading,
    isSuccess: delsuccess,
    refetch: reFetchDelData,
  } = useFetchDeliveryApprovalQuery({
    page: delCurrentPage,
    pageSize: delPageSize,
    search: "",
    orderDir: orderBy,
    orderBy: "id",
  });

  if (delLoading) {
    return <HashLoader />;
  } else if (delsuccess) {
    fetchDelResult = delData.results;
  } else {
    return <div>Error Loading...</div>;
  }

  let data = [];

  if (account_detailed && account_detailed.job_position === 25) {
    data.unshift({
      label: "Withdrawal",
      value: "withdrawal",
      desc: (
        <CompleteTransferWithdrawalTableView TABLE_HEAD_DEL={TABLE_HEAD_WITH} />
      ),
    });
  } else {
    data.unshift({
      label: "Return Seller",
      value: "Return Seller",
      desc: <CompleteTransferRTSTableView TABLE_HEAD_DEL={TABLE_HEAD_RTS} />,
    });

    data.unshift({
      label: "Withdrawal",
      value: "withdrawal",
      desc: (
        <CompleteTransferWithdrawalTableView TABLE_HEAD_DEL={TABLE_HEAD_WITH} />
      ),
    });
    data.unshift({
      label: "Request",
      value: "request",
      desc: (
        <CompleteTransferRequestTableView TABLE_HEAD_DEL={TABLE_HEAD_REQ} />
      ),
    });

    data.unshift({
      label: "Delivery",
      value: "delivery",
      desc: (
        <CompleteTransferDeliveryTableView TABLE_HEAD_DEL={TABLE_HEAD_DEL} />
      ),
    });
    data.unshift({
      label: "Good & Reject",
      value: "good_reject",
      desc: <TransferGoodReject />,
    });
    data.unshift({
      label: "Production",
      value: "production",
      desc: <TransferProduction />,
    });
  }

  return (
    <>
      <div className="container mx-auto px-4 md:mb-28 lg:mb-0">
        <div className="md:hidden flex justify-end mt-[-20px] mb-5 mr-3">
          <PageTitle />
        </div>
        <div className="flex flex-row justify-center md:justify-between items-center ml-3 lg:ml-10 mr-5 lg:mr-28 mb-10 md:mb-16 lg:mb-5">
          <div className="flex items-center space-x-2">
            {/* <div className="flex justify-between items-center ml-3 lg:ml-10 mr-5 lg:mr-28 mb-10 lg:mb-5">
              <div className="flex items-center space-x-2"> */}
            <TbTransferIn className="h-10 w-10  text-[#137944]" />
            <Typography className="text-[#137944] font-bold text-3xl tracking-normal">
              Transfer
            </Typography>
          </div>
          <div className="hidden md:block">
            <PageTitle />
          </div>
        </div>
        <div className="w-[105%] ml-[-3%] md:w-[110%] md:ml-[-5%] lg:w-[100%] lg:ml-[0%]">
          <ApprovalTabComponents data={data} />
        </div>
      </div>
    </>
  );
}

export default Approval;
