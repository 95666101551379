import React, { useState } from "react";
import { Input, Select, Option } from "@material-tailwind/react";

// Utility to get date ranges for "1st-15th" and "16th-last day" periods of a month

const getCurrentPeriod = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();

  // Get the start and end of the period based on the day
  if (day >= 16) {
    const startDate = new Date(year, month, 16);
    const endDate = new Date(year, month + 1, 0); // Last day of the month
    return {
      label: `${startDate.toLocaleString("default", {
        month: "short",
      })} 16-${endDate.getDate()} ${year}`,
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
    };
  } else {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month, 15);
    return {
      label: `${startDate.toLocaleString("default", {
        month: "short",
      })} 1-15 ${year}`,
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
    };
  }
};

const getDatePeriods = (year, month) => {
  const startOfMonth = new Date(year, month, 1);
  const middleOfMonth = new Date(year, month, 15);
  const lastDayOfMonth = new Date(year, month + 1, 0); // Last day of the month

  return [
    {
      label: `${startOfMonth.toLocaleString("default", {
        month: "short",
      })} 1-15 ${year}`,
      startDate: startOfMonth.toISOString().split("T")[0],
      endDate: middleOfMonth.toISOString().split("T")[0],
    },
    {
      label: `${startOfMonth.toLocaleString("default", {
        month: "short",
      })} 16-${lastDayOfMonth.getDate()} ${year}`,
      startDate: new Date(year, month, 16).toISOString().split("T")[0],
      endDate: lastDayOfMonth.toISOString().split("T")[0],
    },
  ];
};

const PeriodSelector = ({ onPeriodChange }) => {
  const today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [currentPeriod, setCurrentPeriod] = useState(getCurrentPeriod());

  // Get periods dynamically based on current year and month
  const periods = getDatePeriods(year, month);

  const handlePeriodChange = (value) => {
    const selectedLabel = value;
    const period = periods.find((p) => p.label === selectedLabel);
    setSelectedPeriod(period);
    if (period) {
      onPeriodChange(period.startDate, period.endDate);
    }
  };

  const handleChangeMonth = (value) => {
    setMonth(value);

    // Dynamically calculate periods for the new month
    const newPeriods = getDatePeriods(year, value);

    // Find the current period based on the day of the month
    const today = new Date();
    const currentDay = today.getDate();
    let period;

    if (currentDay <= 15) {
      period = newPeriods.find((p) => p.label.includes("1-1"));
    } else {
      period = newPeriods.find((p) => p.label.includes("16-"));
    }

    setSelectedPeriod(period);

    // Trigger the onPeriodChange callback if a period is found
    if (period) {
      onPeriodChange(period.startDate, period.endDate);
    }
  };
  return (
    <>
      <div className="flex flex-col gap-2">
        <Input
          type="number"
          value={year}
          onChange={(e) => setYear(parseInt(e.target.value))}
          disabled={true}
        />
      </div>

      <div className="flex flex-col gap-2">
        <Select value={month} onChange={handleChangeMonth} label="Select Month">
          {Array.from({ length: 12 }).map((_, index) => (
            <Option key={index} value={index}>
              {new Date(0, index).toLocaleString("default", {
                month: "long",
              })}
            </Option>
          ))}
        </Select>
      </div>
      <div className="flex flex-col gap-2">
        <Select
          label="Select Period"
          onChange={handlePeriodChange}
          className="rounded-md"
          value={selectedPeriod?.label || currentPeriod.label} // Fallback to currentPeriod's label
        >
          {periods.map((period) => (
            <Option
              key={period.label}
              value={period.label}
              selected={
                (selectedPeriod?.label || currentPeriod.label) === period.label
              } // Match selected or current period
            >
              {period.label}
            </Option>
          ))}
        </Select>
      </div>
    </>
  );
};

export default PeriodSelector;
