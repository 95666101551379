import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, Typography } from "@material-tailwind/react";
import PageTitle from "../page_components/PageTitle";
import ApprovalTabComponents from "../page_components/ApprovalTabComponents";
import { CompleteTableView } from "../page_components/Complete_Table_Outlet_Summary";
import { CompleteTableSRView } from "../page_components/Complete_Table_Sales_Report";
import { CompleteTableSLView } from "../page_components/Complete_Table_Outlet_SL";

import { LuClipboardList } from "react-icons/lu";

function OutletSummary() {
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  useEffect(() => {
    if (
      account_detailed.job_position == 1 ||
      account_detailed.job_position == 5 ||
      account_detailed.user_type == 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position === 20 ||
      account_detailed.job_position === 21
    ) {
    } else {
      // pass
      return;
    }
  }, [token]);

  let data = [];

  data.unshift({
    label: "Sales Report",
    value: "dsr",
    desc: <CompleteTableSRView />,
  });
  data.unshift({
    label: "Summary",
    value: "Outlet",
    desc: <CompleteTableView />,
  });

  return (
    <>
      <div className="container mx-auto px-4 md:mb-28 lg:mb-0">
        <div className="md:hidden flex justify-end mt-[-20px] mb-5 mr-3">
          <PageTitle />
        </div>
        <div className="flex flex-row justify-center md:justify-between items-center ml-3 lg:ml-10 mr-5 lg:mr-28 mb-10 md:mb-16 lg:mb-5">
          <div className="flex items-center space-x-2">
            <LuClipboardList className="h-10 w-10  text-[#137944]" />
            <Typography className="text-[#137944] font-bold text-3xl tracking-normal">
              Outlet Summary
            </Typography>
          </div>
          <div className="hidden md:block">
            <PageTitle />
          </div>
        </div>
        <div className="w-[105%] ml-[-3%] md:w-[110%] md:ml-[-5%] lg:w-[100%] lg:ml-[0%]">
          <ApprovalTabComponents data={data} />
        </div>
      </div>
    </>
  );
}

export default OutletSummary;
