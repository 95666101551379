import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Card,
  Select,
  Option,
} from "@material-tailwind/react";
import { useFetchSRItemsDetailsQuery } from "../../../store";

const TABLE_HEAD = [
  "Code",
  "Name",
  "UM",
  "Daily AVG. Cons.",
  "SL",
  "SOH in Pcs",
  "Exp in Pcs",
  "Order Qty in Pcs/Pcks",
];
const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
const trapMessage = "Are you sure to Transfer these products?";
const user_info = JSON.parse(localStorage.getItem("user_info"));

const currentDate = new Date();
const transferDate = currentDate.toLocaleString(undefined, {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});
let result = [];
let result_item = [];

function SalesReportModal({ srData }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = "p-4 border-b border-blue-gray-50";
  const inputClasses = "flex flex-row items-center";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";

  const { data, isError, isLoading, isSuccess } = useFetchSRItemsDetailsQuery(
    srData.id
  );

  const handleMainClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error loading data</div>;
  } else if (isSuccess) {
    result = data.data;

    result_item = data.data.items;
    console.log(result_item);
    // console.log(account_detailed)
    const formattedDate = srData.created_at
      ? new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(new Date(srData.created_at))
      : "";

    return (
      <>
        <Button onClick={handleOpen} className="bg-[#1AA55D]">
          View
        </Button>

        <Dialog
          size="lg"
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
            {/* <img
            src="https://oodc.com.ph/wp-content/uploads/2023/05/viber_image_2023-05-02_14-20-42-700.png"
            className="aspect-auto w-48"
            alt="logo"
          /> */}
            <Typography
              className="text-green-900"
              variant="h3"
              component={"span"}
            >
              Sales Order Report
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <form>
              <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Sales Report"
                      name="sales_num"
                      value={`${srData.sales_number}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Outlet"
                      name="outlet"
                      value={`${srData.outlet.outlet}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="FC"
                      name="fc"
                      value={`${srData.outlet.fc.name}`}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Date Filed"
                      name="date"
                      value={`${formattedDate}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Route"
                      name="route"
                      value={`${srData.outlet.route.name}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Selected Stock Level"
                      name="SL"
                      value={`${"SL Muliplier " + srData.sl_multiplier}`}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="m-5">
                <Card
                  className="h-full w-full overflow-scroll"
                  component={"span"}
                >
                  <div className="table-container">
                    <table className="w-full min-w-max table-auto text-left ">
                      <thead>
                        <tr>
                          {TABLE_HEAD.map((head) => (
                            <th
                              key={head}
                              className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                              <Typography
                                component={"span"}
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                              >
                                {head}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {result.map((item, index) => {
                          // let difference =
                          //   item.requested_qty - (actualQtys[index] || 0);
                          return (
                            <tr key={index}>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.product.product_code}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.product.name || "N/A"}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.product.weight_box || "NA"}/
                                      {item.product.unit_measurement || "NA"}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td
                                className={classes}
                                style={{ textAlign: "center" }}
                              >
                                <div className="flex items-center justify-center">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.stock_level || 0}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td
                                className={classes}
                                style={{ textAlign: "center" }}
                              >
                                <div className="flex items-center justify-center">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.product.category === 7 ||
                                      item.product.category == 8
                                        ? item.stock_level
                                        : (
                                            item.stock_level *
                                            srData.sl_multiplier
                                          ).toFixed(2)}
                                    </Typography>
                                  </div>
                                </div>
                              </td>

                              <td
                                className={classes}
                                style={{ textAlign: "center" }}
                              >
                                <div className="flex items-center justify-center">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.stock_on_hand}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td
                                className={classes}
                                style={{ textAlign: "center" }}
                              >
                                <div className="flex items-center justify-center">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.reject_qty || 0}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td
                                className={classes}
                                style={{ textAlign: "center" }}
                              >
                                <div className="flex items-center justify-center">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.order_qty_pcs || 0} |{" "}
                                      {item.order_qty || 0}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </div>
            </form>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              className="mr-3"
              onClick={handleMainClose}
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

export default SalesReportModal;
