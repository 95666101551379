import "./App.css";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import Dashboard from "./components/pages/Dashboard";
import Base from "./components/pages/Base";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./components/pages/Login";
import RequestForm from "./components/pages/RequestForm";
import Warehouse_PurchaseOrder from "./components/pages/Warehouse_PurchaseOrder";
import Approval from "./components/pages/Approval";
import Raw_Mats_Delivery from "./components/pages/Raw_Mats_Delivery";
import Delivery from "./components/pages/Delivery";
import Production from "./components/pages/Production";
import Warehouse_Transfer from "./components/pages/Warehouse_Transfer";
import Warehouse_Summary from "./components/pages/Warehouse_Summary";
import OutletSummary from "./components/pages/OutletTab";
import Archive from "./components/pages/Archive";
import Logs from "./components/pages/Logs";
import Notifications from "./components/pages/Notifications";
import DashboardV2 from "./components/pages/DashboardV2";
import Home from "./components/pages/Home";
import Audit from "./components/pages/Audit";
import KbFAQs from "./components/pages/KB Components/KBHome";
import UserSummary from "./components/pages/Users";
import AuditorsSummary from "./components/pages/Auditor";

function App() {
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
  }, [token]);

  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="base" element={<Base />}>
          <Route path="home" element={<Home />} />
          <Route path="dashboard" element={<DashboardV2 />} />
          {/* <Route path="dashboard" element={<Dashboard />} /> */}
          <Route path="requestform" element={<RequestForm />} />
          <Route
            path="warehouse_purchaseorder"
            element={<Warehouse_PurchaseOrder />}
          />
          <Route path="approval" element={<Approval />} />
          <Route
            path="warehouse_raw_materials_delivery"
            element={<Raw_Mats_Delivery />}
          />
          <Route path="production" element={<Production />} />
          <Route path="delivery" element={<Delivery />} />
          <Route path="warehouse_transfer" element={<Warehouse_Transfer />} />
          <Route path="warehouse_summary" element={<Warehouse_Summary />} />
          <Route path="approvals" element={<Approval />} />
          <Route path="delivery" element={<Delivery />} />
          <Route path="outlet" element={<OutletSummary />} />
          <Route path="user" element={<UserSummary />} />
          <Route path="archive" element={<Archive />} />
          <Route path="notification" element={<Notifications />} />
          <Route path="logs" element={<Logs />} />
          <Route path="audit" element={<Audit />} />
          <Route path="kb-faqs" element={<KbFAQs />} />
          <Route path="auditors" element={<AuditorsSummary />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
